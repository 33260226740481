import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import RoomPage from 'components/RoomPage'
import { formatNodes } from 'utils/formatNodes'

function PatioPage({data}) {
	const recommended = formatNodes(data.recommendedQuery)
  const lightData = formatNodes(data.lightQuery)
  const shadeData = formatNodes(data.shadeQuery)

  const pageData = {
    header: {
      title: "Plants for the Patio",
      copy: "It's summer time and outdoor gardening is in full swing. Find plants you love that will bring life to your patio.",
      image: data.greenCouch.childImageSharp.fluid
    },
    recommended: {
      title: "Top Picks",
      cta: {
        title: "View all patio plants",
        link: "/plants?patio"
      },
      items: recommended.slice(0,8)
    },
    sections: [
      {
        title:"Plants that love light",
        image: data.backyard.childImageSharp.fluid,
        items: lightData
      },
      {
        title: "Plants that do well in the shade",
        image: data.porchTable.childImageSharp.fluid,
        items: shadeData
      }
    ]
  }
  return <RoomPage data={pageData}/>
}

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants for your patio"/>
      <PatioPage data={data}/>
    </>
  )
}

export const query = graphql`
  query PatioQuery {
    greenCouch: file(relativePath: { eq: "patio/green-couch.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backyard: file(relativePath: { eq: "patio/backyard.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    porchTable: file(relativePath: { eq: "patio/porch-table.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    recommendedQuery: allStripeSku(
      limit: 15,
      filter: { metadata: { patio: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    lightQuery: allStripeSku(
      limit: 15,
      filter: { metadata: { p_sun: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    shadeQuery: allStripeSku(
      limit: 15,
      filter: { metadata: { p_shade: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
           ...Sku
          }
        }
      }
    }
  }
`